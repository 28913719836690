type VariantType = 'red' | 'success' | 'warning';

interface Label {
  isShow: boolean;
  variant: VariantType;
  value: string;
}

export const useProductProperties = (data: any) => {
  const { t: $t } = useI18n();

  const { attributes } = data;

  const authors = computed(
    () => (data?.attributes?.items || data?.attributes)?.find((e: any) => e.code === 'author')?.selectedOptions ?? []
  );

  const brand = computed(
    () =>
      (data?.attributes?.items || data?.attributes)?.find((e: any) => e.code === 'publisher')?.selectedOptions[0]
        .label ?? ''
  );

  const productLink = computed(() => `/book/${data?.urlKey}`);

  const hasInStock = computed(() => data?.stockStatus === 'IN_STOCK');

  const ratingStar = computed(() => Math.floor(data?.ratingValue));

  const ratingAmount = computed(() => data.ratingValue);

  const labels = computed<Label[]>(() => {
    return [
      {
        isShow: data?.promotionLabel,
        variant: 'warning',
        value: $t('productLabels.promotion'),
      },
      {
        isShow: data?.topLabel,
        variant: 'red',
        value: $t('productLabels.top'),
      },
      {
        isShow: data?.newLabel,
        variant: 'success',
        value: $t('productLabels.new'),
      },
    ];
  });

  const valuePrice = computed(() => {
    return data?.priceRange?.minimumPrice?.finalPrice?.value;
  });

  const valuePriceOld = computed(() => {
    const amountOff = data?.priceRange?.minimumPrice?.discount?.amountOff;
    return amountOff ? data?.priceRange?.minimumPrice?.regularPrice?.value : 0;
  });

  const hasInWishlist = ref(false);

  const featuresData = [
    {
      code: 'author',
      itemprop: 'author',
    },
    {
      code: 'cover',
      isUrl: true,
    },
    {
      code: 'language',
      itemprop: 'inLanguage',
      isUrl: true,
    },
    {
      code: 'pages_count',
      itemprop: 'numberOfPages',
    },
    {
      code: 'publisher',
      itemprop: 'publisher',
    },
    {
      code: 'format',
      isUrl: true,
    },
    {
      code: 'isbn',
      itemprop: 'isbn',
    },
    {
      code: 'period',
      itemprop: 'isbn',
      isUrl: true,
    },
    {
      code: 'year',
      itemprop: 'datePublished',
    },
  ];

  const features = computed(() =>
    attributes?.items?.reduce((acc: any, item: any) => {
      const feature = featuresData.find((feature) => feature.code === item.code);

      if (!feature) {
        return acc;
      }

      const value = item.selectedOptions
        ? item.code === 'author'
          ? item.selectedOptions
          : {
              label: item.selectedOptions[0].label,
              value: item.selectedOptions[0].value,
            }
        : {
            label: item.value,
          };

      const newItem: any = {
        label: item.label,
        id: item.code,
        value,
      };

      if (feature.itemprop) {
        newItem.itemprop = feature.itemprop || null;
      }

      if (feature.isUrl) {
        newItem.url = `/books?${item.code}=${value?.value || value}`;
      }

      acc.push(newItem);

      return acc;
    }, [])
  );

  return {
    authors,
    brand,
    productLink,
    hasInStock,
    valuePrice,
    valuePriceOld,
    hasInWishlist,
    features,
    ratingStar,
    ratingAmount,
    labels,
  };
};
