<template>
  <div :class="['product-stock', { 'product-stock--gray': !hasInStock }]">
    {{ hasInStock ? $t('inStock') : $t('outOfStock') }}
  </div>
</template>

<script lang="ts" setup>
interface Props {
  hasInStock?: boolean;
}

defineProps<Props>();

const { t: $t } = useI18n();
</script>

<style lang="scss" src="./ProductStock.scss" />
